import React, { useEffect, useMemo, useState } from "react";

import TabsNav from "../Components/Navigation/Tabs/TabsNav";
import MobileTabsNav from "../Components/Navigation/Tabs/MobileTabsNav";
import TabView from "../Components/Navigation/Tabs/TabView";
import DetailsContainer from "../Components/Contract/Details/DetailsContainer";
import ValueContainer from "../Components/Contract/Value/ValueContainer";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchClients, fetchUser } from "../api/user";
import { userTypes } from "../utils/constants";
import { useParams } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { createNotification } from "../api/notifications";
import { createEvent } from "../api/events";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { fetchPlayerNote, putPlayerNote } from "../api/playerNotes";
import { useHistory } from "react-router-dom";


const allLabels = ["Contract Details", "Market Value", "Notes"];

const Contract = ({ width }) => {
  const classes = useStyles();
  const { id: playerId } = useParams()

  const [activeTab, setActiveTab] = useState(allLabels[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  //Will need to make some sort of state for individual user
  const { user } = useReduxQuery("User", fetchUser);
  const { agent } = useReduxQuery(
    user?.userType === userTypes.AGENT ? "Agent" : null,
    fetchClients
  );
  const { playerNotes } = useReduxQuery("PlayerNotes", () => fetchPlayerNote({ playerId }));
  const playerNote = Object.values(playerNotes || {}).find((pn) => String(pn.playerId) === String(playerId))

  const selectedUser = user?.allUsers?.find(
    (user) => String(user.id) === String(playerId)
  );

  const isMarketValue = useMemo(() => {
    return selectedUser?.marketValue !== null &&
      (selectedUser?.marketValue?.averageAnnualSalary !== null ||
        selectedUser?.marketValue?.nflRank !== null ||
        selectedUser?.marketValue?.positionRank !== null ||
        selectedUser?.marketValue?.fiveYearProjection !== null ||
        selectedUser?.marketValue?.comparablePlayers.length)
      ? true
      : false;
  }, [selectedUser?.marketValue]);

  const labels = useMemo(
    () => (isMarketValue ? allLabels : allLabels.filter(l => l !== "Market Value")),
    [isMarketValue]
  );

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <h3 style={{
          fontSize: '40px',
          fontWeight: 'bold',
          marginBottom: '12px'
        }}>
          {selectedUser?.firstName} {selectedUser?.lastName}
        </h3>
        {/* User is an agent and is assigned to this user */}
        {user?.userType === userTypes.AGENT && agent?.clients?.[playerId] ? (
          <div style={{
            display: 'flex',
            gap: '8px'
          }}>
            <div>
              <Button
                onClick={() => setIsOpen(true)}
                variant="contained"
                color="secondary"
                style={{
                  textTransform: 'unset',
                  fontWeight: 'bold'
                }}>
                Send Notification
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setIsEventModalOpen(true)}
                variant="contained"
                color="primary"
                style={{
                  textTransform: 'unset',
                  fontWeight: 'bold'
                }}>
                Create Event
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        {selectedUser?.userType === userTypes.PLAYER &&
          (isWidthDown("sm", width) ? (
            <MobileTabsNav
              labels={labels}
              activeTab={activeTab}
              selectTab={setActiveTab}
            />
          ) : (
            <TabsNav
              labels={labels}
              activeTab={activeTab}
              selectTab={setActiveTab}
            />
          ))}
        <TabView>
          {activeTab === 'Contract Details' ? (
            <DetailsContainer
              user={selectedUser}
              parent={
                selectedUser?.userType === userTypes.PARENT ||
                selectedUser?.userType === userTypes.SPOUSE
              }
              page={true}
            />
          ) : activeTab === 'Market Value' ? (
            <ValueContainer user={selectedUser} />
          ) : activeTab === 'Notes' ? (
            <NotesContainer playerNote={playerNote} />
          ) : null}
        </TabView>
      </div>
      <NotificationDialog isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      <EventDialog
        isOpen={isEventModalOpen}
        handleClose={() => setIsEventModalOpen(false)}
      />
    </div>
  );
};

const NotificationDialog = ({
  isOpen,
  handleClose
}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { id: playerId } = useParams()

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsError(false)
    setIsLoading(true)
    try {
      await dispatch(createNotification({
        title,
        description,
        userId: playerId
      }))
      setIsSuccess(true)
    } catch (error) {
      console.error(error?.message || error)
      setIsError(true)
    }
    setIsLoading(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          setTitle("")
          setDescription("")
          setIsError(false)
          setIsSuccess(false)
        }
      }}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Send Notification
      </DialogTitle>
      {isSuccess ? (
        <>
          <DialogContent>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your notification was sent successfully.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label="Title"
              variant="outlined"
              defaultValue={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              fullWidth
              autoFocus
              required
            />
            <TextField
              label="Message"
              variant="outlined"
              defaultValue={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              className={classes.input}
              minRows={6}
              multiline
              fullWidth
              required
            />
            {isError ? (
              <Alert severity="error" style={{
                marginTop: '24px'
              }}>
                <AlertTitle>Error</AlertTitle>
                Your notification failed to send. Please try again.
              </Alert>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
              disabled={isLoading}
            >
              Send
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

const EventDialog = ({
  isOpen,
  handleClose
}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { id: playerId } = useParams()

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsError(false)
    setIsLoading(true)
    try {
      await dispatch(createEvent({
        title,
        description,
        startDate,
        endDate: startDate,
        assignedTo: playerId,
        lat: 0,
        lon: 0,
      }))
      setIsSuccess(true)
    } catch (error) {
      console.error(error?.message || error)
      setIsError(true)
    }
    setIsLoading(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          setTitle("")
          setDescription("")
          setStartDate("")
          setIsError(false)
          setIsSuccess(false)
        }
      }}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create Event
      </DialogTitle>
      {isSuccess ? (
        <>
          <DialogContent>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your event was created successfully.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label="Title"
              variant="outlined"
              defaultValue={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              fullWidth
              autoFocus
              required
            />
            <TextField
              label="Message"
              variant="outlined"
              defaultValue={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              className={classes.input}
              minRows={6}
              multiline
              fullWidth
              required
            />
            <TextField
              label="Start Date"
              variant="outlined"
              type="date"
              defaultValue={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
              className={classes.input}
              fullWidth
              required
            />
            {isError ? (
              <Alert severity="error" style={{
                marginTop: '24px'
              }}>
                <AlertTitle>Error</AlertTitle>
                Failed to create event. Please try again.
              </Alert>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              size="large"
              style={{
                textTransform: 'unset',
                fontWeight: 'bold'
              }}
              disabled={isLoading}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

const NotesContainer = ({ playerNote }) => {
  const { id: playerId } = useParams()

  const classes = useStyles();
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState(playerNote?.note || '');

  const [isSaving, setIsSaving] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const history = useHistory()

  const handleNoteUpdate = (val) => {
    setInputValue(val)
    setIsDirty(val !== playerNote?.note || '')
  }

  const handleSaveNote = async () => {
    try {
      if (isDirty) {
        setIsSaving(true)
        await dispatch(putPlayerNote({
          id: playerNote?.id,
          note: inputValue,
          playerId
        }));
        setIsDirty(false)
        setIsSaving(false)
        setIsSuccess(true)
        setTimeout(() => setIsSuccess(false), 1000)
      }
    } catch (error) {
      setIsSaving(false)
      alert(error);
    }
  };

  // Handle navigating away if note hasnt been saved
  useEffect(() => {
    const unblock = history.block(() => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          "Are you sure you want to leave? Changes you made may not be saved."
        );
        return confirmLeave;
      }
      return true;
    });

    return () => unblock();
  }, [isDirty, history]);

  // Handle refreshing if note hasnt been saved
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);

  return (
    <div>
      <div>
        <div style={{
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: "24px"
        }}>
          <h2>Notes</h2>
          <button
            style={{
              color: "#4B7BEC",
              backgroundColor: "#4B7BEC20",
              borderRadius: 25,
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              textTransform: "uppercase",
              fontSize: 14,
              fontWeight: "bold",
              border: "none",
              cursor: "pointer",
              fontFamily: "inherit"
            }}
            onClick={handleSaveNote}
          >
            Save Note
          </button>
        </div>
        <div className={classes.details}>
          <p style={{
            color: 'gray',
          }}>
            {playerNote?.updatedAt
              ? (<span>Last saved: {dayjs(playerNote.updatedAt).format("MMMM D, YYYY h:mmA")}</span>)
              : null}
          </p>
          <div>
            {isSaving ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                color: "#4B7BEC",
              }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="sm"
                  spin
                />
                <span>Saving...</span>
              </div>
            ) : isSuccess ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                color: "#008907"
              }}>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                />
                <span>Saved!</span>
              </div>
            ) : (
              <p style={{
                color: '#e48c00',
                visibility: isDirty ? 'visible' : 'hidden'
              }}>
                <span>You have unsaved changes</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <TextField
        label="Player Note"
        variant="outlined"
        defaultValue={inputValue}
        onChange={(e) => handleNoteUpdate(e.target.value)}
        className={classes.input}
        placeholder="Write a note..."
        minRows={24}
        multiline
        fullWidth
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
  },
  inner: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    }
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    resize: 'vertical'
  },
  textarea: {
    marginTop: theme.spacing(1),
    borderColor: theme.palette.text.secondary,
    borderWidth: 1,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    width: '100%',
    resize: 'vertical'
  },
  details: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse',
    flex: 1,
  }
}));

export default withWidth()(Contract);