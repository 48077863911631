import React from "react";
import Cal from "./Cal";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import stockImg from "../../images/stock-football.jpg";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const useStyles = makeStyles((theme) => ({
  eventCardGridItem: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
  },
  featureCardContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "500px",
    boxShadow: "1px 1px 5px",
    borderRadius: "6px",
    backgroundImage: `url(${stockImg})`,
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    margin: "auto",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardInnerContainer: {
    height: "60%",
    width: "100%",
    padding: "2% 18px",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 10px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  headingCal: {
    display: "flex",
    flexDirection: "column",
    marginTop: "21px",
  },
  h4: {
    ...setStyles.sofiaProBold,
    fontSize: "30px",
    width: "90%",
    color: "white",
    margin: "0px",
    lineHeight: 1,
  },
  p1: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "11px",
    color: "#e9cb93",
    paddingBottom: "8px",
    paddingTop: "5px",
  },
  p2: {
    ...setStyles.sofiaProRegular,
    fontSize: "16px",
    color: "white",
    lineHeight: "1.375",
    opacity: 0.88,
  },
  p3: {
    color: "white",
    ...setStyles.sofiaProSemiBold,
    fontSize: "14px",
    textTransform: "uppercase",
  },
  link: {
    textDecoration: "none",
  },
  date: {
    color: "red",
    fontSize: "11px",
    ...setStyles.sofiaProSemiBold,
  },
}));

const FeatureCardSmall = ({
  heading,
  location,
  date,
  content,
  id,
  coverUrl,
}) => {
  const classes = useStyles();

  const d = new Date(date);
  const day = d.getUTCDay();
  const month = d.getUTCMonth();

  return (
    <div className={classes.eventCardGridItem}>
      <div
        className={classes.featureCardContainer}
        style={{
          backgroundImage: coverUrl ? `url(${coverUrl})` : `url(${stockImg})`,
        }}
      >
        <a href={`/event/${id}`} className={classes.link}>
          <div className={classes.cardInnerContainer}>
            <Cal month={months[month]} day={d.getUTCDate()} />
            <div className={classes.headingCal}>
              <p className={classes.date}>
                {days[day] + ", " + months[month] + " " + d.getUTCDate()}
              </p>
              <h4 className={classes.h4}>{heading}</h4>
              <p className={classes.p1}>{location}</p>
            </div>

            <p className={classes.p3}>DETAILS</p>
            <p className={classes.p2}>{content}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default FeatureCardSmall;
