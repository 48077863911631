import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    display: "flex",
    width: "100%",
    textAlign: "left",
    margin: "auto",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      maxWidth: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  img: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  textContainer: {
    paddingLeft: "15px",
  },
  h2: {
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  p: {
    color: "#FFFFFF",
    fontFamily: setStyles.sofiaProBold,
    fontSize: "14px",
  },
  span1: {
    color: "#A28C62",
    paddingRight: "20px",
  },
  span2: {
    paddingRight: "20px",
  },
}));

const WelcomeHeader = ({ hide }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user.data);

  if (!hide && user) {
    return (
      <div style={{
        padding: "110px 0px",
      }}>
        <div className={classes.welcomeContainer}>
          <img
            src={
              user?.profileUrl === null ? user?.team?.logoUrl : user?.profileUrl
            }
            alt=""
            className={classes.img}
          />
          <div className={classes.textContainer}>
            <h2
              className={classes.h2}
            >{`${user?.firstName} ${user?.lastName}`}</h2>
            <p className={classes.p}>
              <span className={classes.span1}>
                {user?.contract?.position?.toUpperCase()}
              </span>{" "}
              <span className={classes.span2}>
                {`${user?.team?.city?.toUpperCase() || ""} ${user?.team?.name?.toUpperCase() || ""
                  }`}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default WelcomeHeader;
