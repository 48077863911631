// Actions
const LOAD_ALL = "notes/LOAD_ALL";
const LOAD_UNIQUE = "notes/LOAD_UNIQUE";
const ADD_NOTE = "notes/ADD_NOTE"
const UPDATE_NOTE = "notes/UPDATE_NOTE"
const REMOVE_NOTE = "notes/REMOVE_NOTE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.notes.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: { ...state.data, [action.note.id]: action.note },
      };
    case ADD_NOTE:
      return {
        ...state,
        data: { ...state.data, [action.note.id]: action.note },
      };
    case UPDATE_NOTE:
      return {
        ...state,
        data: { ...state.data, [action.note.id]: action.note },
      };
    case REMOVE_NOTE:
      const newNoteData = { ...state.data };
      delete newNoteData[action.note.id];
      return {
        ...state,
        data: newNoteData
      };
    default:
      return state;
  }
}

// Action Creators
export function loadNotes(notes) {
  return { type: LOAD_ALL, notes };
}
export function loadNote(note) {
  return { type: LOAD_UNIQUE, note };
}
export function addNote(note) {
  return { type: ADD_NOTE, note };
}
export function updateNote(note) {
  return { type: UPDATE_NOTE, note };
}
export function removeNote(note) {
  return { type: REMOVE_NOTE, note };
}
