import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewNotifications,
  updateNotification,
} from "../api/notifications";
import { notificationTypes, notificationURLTypes } from "../utils/constants";
import history from "../history";
import { useSnackbar } from "notistack";

const notificationQueue = [];
const useNotifications = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const { closeSnackbar } = useSnackbar();
  const notifications = useSelector((state) => state.notifications.data);

  const clickHandler = async (snackbarId, {
    id: notificationId,
    notification
  }) => {
    closeSnackbar(snackbarId);
    await dispatch(updateNotification(notificationId, { read: true }));
    const { type, objectId } = notification;
    if (type === notificationTypes.GLOBAL) return;
    const urlType = notificationURLTypes[type];
    if (type === notificationTypes.CONTACT) {
      history.push(`/${urlType}`);
    } else if (urlType && objectId) {
      history.push(`/${urlType}/${objectId}`);
    }
  };

  useEffect(() => {
    if (notifications) {
      Object.values(notifications || [])
        .filter((n) => n.read === false)
        .forEach((notification) => {
          const action = (snackbarId) => (
            <Button
              style={{ color: "white" }}
              colorScheme="teal"
              onClick={() => clickHandler(snackbarId, notification)}
            >
              {notification?.notification?.type === notificationTypes.GLOBAL
                ? "Close"
                : "See"}
            </Button>
          );
          if (
            (notificationQueue || [])
              .map((nq) => nq.title)
              .includes(notification.notification?.notification?.title)
          )
            return;
          notificationQueue.push({
            title: notification?.notification?.title,
            options: {
              autoHideDuration: 4000,
              action,
            },
          });
        });
    }
  }, [notifications]);

  useEffect(() => {
    (notificationQueue || []).forEach((notification) =>
      enqueueSnackbar(notification.title, notification.options)
    );
  }, [notificationQueue]);

  const getNewNotifications = () =>
    setInterval(async () => await dispatch(fetchNewNotifications()), 5000);

  useEffect(() => {
    getNewNotifications();
  }, []);
};
export default useNotifications;
