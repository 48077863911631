import React from "react";
import { useDispatch } from "react-redux";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { createNote, fetchNotes } from "../api/notes";
import dayjs from "dayjs";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import blueGrey from '@material-ui/core/colors/blueGrey';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  stretchedLink: {
    '&::after': {
      position: "absolute",
      inset: 0,
      zIndex: 1,
      content: '""',
    }
  },
  button: {
    width: "100%",
    height: "50px",
    border: "none",
    borderTop: "1px solid lightgrey",
    fontSize: "14px",
    color: "rgb(0,0,0,0.5)",
    ...setStyles.sofiaProSemiBold,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function NotesList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()
  const { notes } = useReduxQuery("Notes", () => fetchNotes());

  const notesList = notes ? Object.keys(notes).map(id => notes[id]) : []
  const formattedList = notesList.map((note) => {
    // Convert note to default value
    let preview = note.note || 'New note'
    if (preview.includes('\n')) {
      // Slice preview text at the first newLine character
      preview = note.note.slice(0, note.note.indexOf('\n'))
    }
    // Manually truncate preview text for screenreader users
    const accessiblePreview = preview.slice(0, 120)
    return {
      ...note,
      preview,
      accessiblePreview
    }
    // Sort newest to oldest
  }).sort((a, b) => dayjs(b.updatedAt).isAfter(dayjs(a.updatedAt)) ? 1 : -1)

  const handleCreateNote = async () => {
    const newNote = await dispatch(createNote());
    history.push(`/notes/${newNote.id}`)
  }

  return (
    <div className={classes.container}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h2 className={classes.h2}>All Notes</h2>
        <button
          style={{
            color: "#4B7BEC",
            backgroundColor: "#4B7BEC20",
            borderRadius: 25,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"
          }}
          onClick={handleCreateNote}
        >
          Create Note
        </button>
      </div>


      {formattedList.length > 0 ?
        (
          <ul>
            {formattedList.map((note, idx) => (
              <li
                key={note.id}
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  borderTop: idx !== 0 ? '1px solid black' : 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  lineClamp: 2,
                  overflow: 'hidden',
                  position: "relative"
                }}>
                <div style={{ overflow: 'hidden', }}>
                  <Link
                    to={`/notes/${note.id}`}
                    className={classes.stretchedLink}
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      paddingRight: '16px',
                      textDecoration: 'none',
                      color: 'black',
                    }}>
                    <p
                      aria-hidden="true"
                      style={{
                        fontSize: '20px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {note.preview}
                    </p>
                    <Typography variant="srOnly">
                      {note.accessiblePreview}
                    </Typography>
                  </Link>
                  <p
                    style={{
                      fontSize: '14px',
                      color: 'gray',
                    }}
                  >
                    {dayjs(note.updatedAt).format("MMMM D, YYYY h:mmA")}
                  </p>
                </div>
                <div>
                  <ChevronRightIcon />
                </div>
              </li>
            ))}

          </ul>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: blueGrey[50],
              borderRadius: '12px',
              height: '96px'
            }}>
            <div>You have no notes</div>
          </div>
        )}
    </div>
  )
}
