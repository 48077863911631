import React, { useMemo, useEffect } from "react";

import { Grid } from "@material-ui/core";

import IconLink from "./IconLink";
import MoreButton from "./MoreButton";
import ExpandedMenu from "./ExpandedMenu";

import HomeIcon from "../../../images/Icons/Home";
import CareerIcon from "../../../images/Icons/Career";
import CalendarIcon from "../../../images/Icons/Calendar";
import KnowledgeIcon from "../../../images/Icons/Knowledge";
import NotificationsIcon from "../../../images/Icons/Notifications";
import ParentIcon from "../../../images/Icons/Parent";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchNotifications } from "../../../api/notifications";
import Badge from "../../Badge";
import { useLocation } from "react-router-dom";
import { fetchUser } from "../../../api/user";
import { playerStatusTypes, userTypes } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  mainMenuContainer: {
    width: "437px",
    padding: "24px 0px",
    ...setStyles.setFlexRow,
    margin: "auto 0px auto auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "auto",
      maxWidth: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "300px",
      padding: "24px 0px",
    },
  },
  badgeContainer: {
    position: "relative",
  },
}));

const MainMenu = ({ openMenu, showMore, closeMenu, pathName }) => {
  const classes = useStyles();
  const { notifications } = useReduxQuery("Notifications", fetchNotifications);
  const { user } = useReduxQuery("User", fetchUser);

  const nbUnreadNotification = useMemo(
    () =>
      Object.values(notifications || []).filter((n) => !n.read)?.length || 0,
    [notifications]
  );


  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/login") {

      let url = "https://chat.dante-ai.com/embed?"
      const defaultParams = {
        modeltype: "gpt-4-omnimodel",
        logo: "dHJ1ZQ==",
        mode: "false",
      }

      const userType = user?.userType
      const playerStatus = user?.status

      if (userType === userTypes.PLAYER) {
        if (playerStatus === playerStatusTypes.RETIRED) {
          // Retired player
          url += new URLSearchParams({
            ...defaultParams,
            kb_id: '20f324e0-1050-488d-a9a3-f596f635c91f',
            token: '9b8704a5-cb90-4414-8a7e-003f212a9a04'
          }).toString();
        } else {
          // Non-Retired player
          url += new URLSearchParams({
            ...defaultParams,
            kb_id: 'e5f0311b-ee71-4d01-adb4-7f284686acc2',
            token: '8bc8b7db-fa12-47f5-99e7-5bcf9ebae632'
          }).toString();
        }
      }
      if (userType === userTypes.COLLEGE) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: '95a03610-6b2b-40e6-ac8d-1360c0580b93',
          token: '66048955-c74d-4e1c-9169-bec186f01c11'
        }).toString();
      } else if (userType === userTypes.COACH) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: '2b4770fa-1540-4e05-bfdb-29544c0b421b',
          token: '177770d7-a1e4-4f21-a53b-6da4fd550798'
        }).toString();
      } else if (userType === userTypes.AGENT) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: 'e8ee652c-27fd-4ffb-82ff-6b6772951b5d',
          token: 'ea81e9a0-14fd-4cf8-a745-92449d508cd4'
        }).toString();
      } else if (userType === userTypes.EXECUTIVE) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: '84a552eb-45c4-4b0f-add8-2693b15fecd5',
          token: '304638a1-6426-4bfd-9604-1a95c46764a2'
        }).toString();
      } else if (userType === userTypes.PARENT || userType === userTypes.SPOUSE) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: '040e377a-dafa-4758-ab34-d414e440bb1f',
          token: 'b83c75c7-a0b6-41f4-a4c3-cdd3f86917ef'
        }).toString();
      } else if (userType === userTypes.REPORTER) {
        url += new URLSearchParams({
          ...defaultParams,
          kb_id: 'b1ed776d-4e56-4ae2-a99c-8e65bcffb140',
          token: '58da82c4-dec1-4d81-acd6-c6a6af3bdd99'
        }).toString();
      }

      window.danteEmbed = url
      const script = document.createElement("script");
      script.src = "https://chat.dante-ai.com/bubble-embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [location.pathname, user?.status, user?.userType]);

  return (
    <Grid item md={6} sm={12} className={classes.mainMenuContainer}>
      <IconLink text="Home" url="/" active={pathName === "/"}>
        <HomeIcon active={pathName === "/"} />
      </IconLink>
      {user?.userType !== userTypes.PARENT &&
        user?.userType !== userTypes.SPOUSE && (
          <IconLink
            text="Career"
            url="/contract"
            active={pathName === "/contract"}
          >
            <CareerIcon active={pathName === "/contract"} />
          </IconLink>
        )}
      {user?.userType === userTypes.PARENT && (
        <IconLink text="Parent" url="/parent" active={pathName === "/parent"}>
          <ParentIcon active={pathName === "/parent"} />
        </IconLink>
      )}
      {user?.userType === userTypes.SPOUSE && (
        <IconLink text="Spouse" url="/spouse" active={pathName === "/spouse"}>
          <ParentIcon active={pathName === "/spouse"} />
        </IconLink>
      )}
      <IconLink text="Events" url="/events" active={pathName === "/events"}>
        <CalendarIcon active={pathName === "/events"} />
      </IconLink>
      <IconLink
        text="Education"
        url="/playerEducation"
        active={pathName === "/playerEducation"}
      >
        <KnowledgeIcon active={pathName === "/playerEducation"} />
      </IconLink>
      <div className={classes.badgeContainer}>
        {nbUnreadNotification > 0 && <Badge nb={nbUnreadNotification} />}
        <IconLink text="Notifications" url="/notifications">
          <NotificationsIcon />
        </IconLink>
      </div>
      <MoreButton moreHandler={openMenu} />
      <ExpandedMenu showMore={showMore} closeMenu={closeMenu} user={user} />
    </Grid>
  );
};

export default MainMenu;
