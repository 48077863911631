// Actions
const LOAD_ALL = "playerDeals/LOAD_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.playerDeals.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    default:
      return state;
  }
}

// Action Creators
export const getPlayerDeals = (playerDeals) => ({
  type: LOAD_ALL,
  playerDeals,
});
