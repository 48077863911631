import React from "react";

import StatsContainer from "../../Dashboard/StatsContainer";
import ContractInfo from "./ContractInfo";
import ContractDetailsTable from "./ContractDetailsTable";
import ContractSpotracSalaryChart from "./ContractSpotracSalaryChart";
import { userTypes } from "../../../utils/constants";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchPlayerDeals } from "../../../api/playerDeal";
import { Box, CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import setStyles from "../../../setStyles";


const useStyles = makeStyles((theme) => ({
  header: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  cell: {
    color: "#05070B",
    fontSize: "14px",
    ...setStyles.sofiaProRegular,
  }
}))

const formatMoney = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

const DetailsContainer = ({ selected, user, parent, page }) => {
  return (
    <>
      <StatsContainer
        contract={true}
        selected={selected}
        user={user}
        parent={parent}
        page={page}
      />
      {user ? <DealsTable user={user} /> : null}
      <ContractInfo selected={selected} user={user} />
      {((user?.userType === userTypes.PLAYER && user?.spotracId) ||
        selected) && [
          <ContractDetailsTable selected={selected} user={user} key={"1"} />,
          <ContractSpotracSalaryChart
            user={user}
            selected={selected}
            key={"2"}
          />,
        ]}
    </>
  );
};


const DealsTable = ({ user }) => {
  const classes = useStyles();

  const { loadingPlayerDeals, playerDeals } = useReduxQuery("PlayerDeals", () => fetchPlayerDeals(user.id));

  const deals = Object.values(playerDeals || {}).filter(pd => Number(pd.userId) === Number(user.id))


  return (
    <div style={{
      marginTop: '48px'
    }}>
      <h5 style={{ textTransform: 'uppercase' }}>Player Deals</h5>
      <Paper elevation={4}
        style={{
          marginTop: '24px'
        }}>
        {loadingPlayerDeals ? (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: "80px"
          }}>
            <CircularProgress />
          </div>
        ) : deals.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} component="th">Company</TableCell>
                  <TableCell className={classes.header} component="th">Deal Length</TableCell>
                  <TableCell className={classes.header} component="th">Total Value</TableCell>
                  <TableCell className={classes.header} component="th">Weekly Pay</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deals.map((deal) => (
                  <TableRow key={deal.id}>
                    <TableCell className={classes.cell}>{deal.company}</TableCell>
                    <TableCell className={classes.cell}>{deal.duration}</TableCell>
                    <TableCell className={classes.cell}>{isNaN(deal.totalValue) ? '-' : `${formatMoney(deal.totalValue)}`}</TableCell>
                    <TableCell className={classes.cell}>{isNaN(deal.weeklyPay) ? '-' : `${formatMoney(deal.weeklyPay)}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px'
          }}>
            <p>Player has no deals</p>
          </div>
        )}
      </Paper>
    </div>
  )
}


export default DetailsContainer;
