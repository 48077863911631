import React, { useMemo, useState } from "react";
import {
  TextField,
  CircularProgress,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  fetchChildren,
  fetchUsers,
  fetchClients,
  updateParent,
  updateAgent,
  createUser,
} from "../../api/user";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { playerStatusTypes, userTypes } from "../../utils/constants";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import setStyles from "../../setStyles";
import Arrow from "../../images/Icons/Arrow";
import { Alert, AlertTitle } from "@material-ui/lab";


const AllUsers = ({ selected, fullWidth, isProspects }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState("");
  const { user, loadingUser } = useReduxQuery("User", fetchUsers);
  const [loadingList, setLoadingList] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  // Always call both hooks regardless of the condition
  const parentQuery = useReduxQuery(
    user?.userType === userTypes.PARENT || user?.userType === userTypes.SPOUSE
      ? "Parent"
      : null,
    fetchChildren
  );
  const agentQuery = useReduxQuery(
    user?.userType === userTypes.AGENT ? "Agent" : null,
    fetchClients
  );

  // Destructure the results with default values if the hooks were not called
  const { parent = {} } = parentQuery;
  const agent = agentQuery?.agent?.clients || {};

  const allPlayers = useMemo(() => {
    let sortedPlayerList = (user?.allUsers || [])
      .filter((player) => player.userType === userTypes.PLAYER)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    if (selected) {
      if (user?.userType === userTypes.PARENT || user?.userType === userTypes.SPOUSE) {
        sortedPlayerList = sortedPlayerList.filter((player) => parent[player.id]);
      } else if (user?.userType === userTypes.AGENT) {
        sortedPlayerList = sortedPlayerList.filter((player) => agent[player.id])
          .filter((player) => isProspects
            ? player.status === playerStatusTypes.PROSPECT
            : player.status !== playerStatusTypes.PROSPECT
          );
      }
    }

    return sortedPlayerList;
  }, [user?.allUsers, user?.userType, selected, parent, isProspects, agent]);

  const filteredPlayers = useMemo(() => {
    return allPlayers.filter((player) =>
      `${player.firstName} ${player.lastName}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }, [allPlayers, value]);

  const changeParentState = async (playerId) => {
    setLoadingList({ ...loadingList, [playerId]: true });
    await dispatch(updateParent({ playerId }));
    setLoadingList({ ...loadingList, [playerId]: false });
  };

  const changeClientState = async (playerId) => {
    setLoadingList({ ...loadingList, [playerId]: true });
    await dispatch(updateAgent({ playerId }));
    setLoadingList({ ...loadingList, [playerId]: false });
  };

  const navigateToPlayer = (player) => {
    history.push({
      pathname: `${user?.userType === userTypes.AGENT ? "Agent/" : ""
        }Contract/${player.id}`,
      selectedUser: player,
      parent:
        user?.userType === userTypes.PARENT ||
        user?.userType === userTypes.SPOUSE,
    });
  };

  const statusColors = {
    Active: { color: "green" },
    FreeAgent: { color: "blue" },
    InjuredReserve: { color: "red" },
    Retired: { color: "gray" },
    PUP: { color: "orange" },
    NFI: { color: "orange" },
    PracticeSquad: { color: "purple" },
  };

  return (
    <Grid container spacing={2}>
      {loadingUser ? (
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} color="inherit" />
          </div>
        </Grid>
      ) : (
        <>
          {allPlayers && allPlayers.length > 0 && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="Search for a user..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {filteredPlayers && filteredPlayers.length > 0 ? (
            filteredPlayers.map((player, index) => (
              <Grid
                item
                {...(!fullWidth ? { xs: 12 } : { xs: 12, sm: 6, md: 4, lg: 4 })}
                key={index}
              >
                <Card
                  key={index}
                  onClick={() => navigateToPlayer(player)}
                  style={{ cursor: "pointer" }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h3>{`${player?.firstName} ${player?.lastName}`}</h3>
                      <div style={{ marginLeft: "10px" }}>
                        {loadingList[player.id] ? (
                          <CircularProgress size={30} color="gray" />
                        ) : (
                          <FontAwesomeIcon
                            icon={
                              (parent && parent[player.id]) ||
                                (agent && agent[player.id])
                                ? faBookmark
                                : faBookmarkRegular
                            }
                            size="lg"
                            color={
                              (parent && parent[player.id]) ||
                                (agent && agent[player.id])
                                ? "#fcdb03"
                                : "black"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); // Stop propagation to prevent the parent click event
                              if (
                                user?.userType === userTypes.PARENT ||
                                user?.userType === userTypes.SPOUSE
                              )
                                changeParentState(player.id);
                              if (user?.userType === userTypes.AGENT)
                                changeClientState(player.id);
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <p>
                      {player?.team
                        ? `${player?.team?.city} ${player?.team?.name}`
                        : "No Team"}
                    </p>
                    {player?.type !== "None" && <p>{player?.type}</p>}
                    <p style={statusColors[player?.status?.replace(/\s/g, "")]}>
                      {player?.status}
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              {selected && allPlayers?.length === 0 && (
                <p>
                  {user?.userType === userTypes.PARENT
                    ? "You currently do not have any players selected as children."
                    : user?.userType === userTypes.SPOUSE
                      ? "You currently do not have any players selected as a spouse."
                      : `You currently do not have any players selected as ${isProspects ? 'prospects' : 'clients'}.`}
                </p>
              )}
              {(!selected || allPlayers?.length > 0) && (
                <p>
                  There are currently no players in the database that match your
                  search criteria.
                </p>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  requestButton: {
    display: "inline-flex",
    alignItems: "center",
    height: "50px",
    paddingRight: "24px",
    paddingLeft: "24px",
    color: "#4B7BEC",
    backgroundColor: "#4B7BEC20",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  arrow: {
    paddingLeft: "16px",
  },
}))

export default AllUsers;
