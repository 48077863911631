import { axiosHandler } from "../lib/utils";
import { loadPlayerNote, updatePlayerNote } from "../redux/playerNotes";


export const putPlayerNote = (data) => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `player-notes`,
            data,
            method: "PUT",
            state,
        });
        await dispatch(updatePlayerNote(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const fetchPlayerNote = ({
    playerId
}) => async (dispatch, getState) => {

    const params = new URLSearchParams({
        playerId,
    })
    try {
        const state = getState();
        const json = await axiosHandler({
            url: 'player-notes?' + params,
            state,
        });
        await dispatch(loadPlayerNote(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};
