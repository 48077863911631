import { axiosHandler } from "../utils/utils";

export const requestClient = ({
  playerName = ''
}) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `request/request-client`,
      method: "POST",
      data: {
        playerName
      },
      state: getState(),
    });
    return json.data;
  } catch (error) {
    throw error;
  }
};