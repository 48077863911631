import { axiosHandler } from "../lib/utils";
import { addNote, loadNote, loadNotes, removeNote, updateNote } from "../redux/notes";

export const createNote = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `notes`,
            data: {},
            method: "POST",
            state,
        });
        await dispatch(addNote(json.data));
        return json.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const deleteNote = (id) => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `notes/${id}`,
            method: "DELETE",
            state,
        });
        await dispatch(removeNote(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const putNote = (data) => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `notes/${data.id}`,
            data,
            method: "PUT",
            state,
        });
        await dispatch(updateNote(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const fetchNotes = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `notes`,
            state,
        });
        await dispatch(loadNotes(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const fetchNote = (id) => async (dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: `notes/${id}`,
            state,
        });
        await dispatch(loadNote(json.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};
