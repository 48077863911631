// Actions
const LOAD_CLIENTS = "user/LOAD_CLIENTS";
const ADD_CLIENT = "user/ADD_CLIENT";
const REMOVE_CLIENT = "user/REMOVE_CLIENT";
const LOAD_AGENT_NOTES = "user/LOAD_AGENT_NOTES";
const ADD_AGENT_NOTE = "user/ADD_AGENT_NOTE";
const REMOVE_AGENT_NOTE = "user/REMOVE_AGENT_NOTE";
const UPDATE_AGENT_NOTE = "user/UPDATE_AGENT_NOTE";

// Reducer
const initialState = {
  data: {
    clients: {},
    notes: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CLIENTS:
      const clientsObject = action.clients.reduce((acc, client) => {
        acc[client.playerId] = client;
        return acc;
      }, {});

      return { ...state, data: { ...state.data, clients: clientsObject } };
    case ADD_CLIENT:
      return {
        ...state,
        data: {
          ...state.data,
          clients: {
            ...state.data.clients,
            [action.client.playerId]: action.client,
          },
        },
      };
    case REMOVE_CLIENT:
      const newClientData = { ...state.data.clients };
      delete newClientData[action.client.playerId];
      return { ...state, data: { ...state.data, clients: newClientData } };
    case LOAD_AGENT_NOTES:
      const notesObject = action.notes.reduce((acc, note) => {
        acc[note.id] = note;
        return acc;
      }, {});
      return { ...state, data: { ...state.data, notes: notesObject } };
    case ADD_AGENT_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: { ...state.data.notes, [action.note.id]: action.note },
        },
      };
    case REMOVE_AGENT_NOTE:
      const newNoteData = { ...state.data.notes };
      delete newNoteData[action.note.id];
      return { ...state, data: { ...state.data, notes: newNoteData } };
    case UPDATE_AGENT_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: { ...state.data.notes, [action.note.id]: action.note },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export function loadClients(clients) {
  return { type: LOAD_CLIENTS, clients };
}
export function addClient(client) {
  return { type: ADD_CLIENT, client };
}
export function removeClient(client) {
  return { type: REMOVE_CLIENT, client };
}
export function loadAgentNotes(notes) {
  return { type: LOAD_AGENT_NOTES, notes };
}
export function addAgentNote(note) {
  return { type: ADD_AGENT_NOTE, note };
}
export function removeAgentNote(note) {
  return { type: REMOVE_AGENT_NOTE, note };
}
export function updateAgentNote(note) {
  return { type: UPDATE_AGENT_NOTE, note };
}
