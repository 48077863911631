import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { submitPlayerDeal } from "../api/playerDeal";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  p: {
    color: "#404040",
    opacity: 0.8,
    ...setStyles.sofiaProRegular,
    fontSize: "18px",
  },
}));

const defaultValues = {
  term: "",
  exclusivity: "",
  lastDeal: [{
    value: ''
  }],
  twitterFollowers: "",
  instagramFollowers: "",
  tiktokFollowers: "",
  deliverables: "",
  stats: "",
  accolades: "",
  interest: "",
}

export default function DealValue() {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState()

  const { control, handleSubmit, reset } = useForm({
    defaultValues
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lastDeal",
  });

  const onSubmit = async (data) => {
    try {
      const { lastDeal, ...rest } = data
      setLoading(true);
      await dispatch(submitPlayerDeal({
        ...rest,
        lastDeal: lastDeal.map(ld => ld.value)
      }))
      setSuccess(true)
      setLoading(false);
      window.scrollTo(0, 0)
      reset(defaultValues)
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  }

  const interestOptions = [
    {
      value: "Don't Care",
      label: "Don't Care"
    },
    {
      value: "If the Money is Good",
      label: "If the Money is Good"
    },
    {
      value: "I'm Interested",
      label: "I'm Interested"
    },
    {
      value: "I Really Like Them",
      label: "I Really Like Them"
    },
    {
      value: "Can't Pass it Up!",
      label: "Can't Pass it Up!"
    },
  ]

  return (
    <div className={classes.container}>
      <h2 className={classes.h2}>What's my deal worth?</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success ? (
          <Alert
            severity="success"
            style={{
              marginBottom: "24px"
            }}>
            <AlertTitle>Success</AlertTitle>
            Your deal was submitted successfully.
          </Alert>
        ) : null}
        <div>
          <div>
            <Controller
              control={control}
              name="term"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Deal Term"
                  helperText="How long is the deal for?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="exclusivity"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Exclusivity"
                  helperText="Are they limited opportunities from other companies?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div style={{
            marginTop: "16px"
          }}>
            {fields.map((field, index) => (
              <div
                key={field.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: "16px"
                }}
              >
                <div style={{ width: '12px' }}>
                  <span>{index + 1}.</span>
                </div>
                <Controller
                  control={control}
                  name={`lastDeal.${index}.value`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Value of Last Deal"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value}
                      required
                    />
                  )}
                />
                <IconButton
                  color="secondary"
                  onClick={() => remove(index)}
                  aria-label="remove"
                  disabled={fields.length === 1}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    size="sm"
                  />
                </IconButton>
              </div>
            ))}

            <div style={{
              paddingLeft: '20px'
            }}>
              <Button
                color="primary"
                variant="contained"
                style={{
                  marginTop: "16px"
                }}
                onClick={() => append({ value: '' })}
              >
                Add Deal
              </Button>
            </div>
            {/* <Controller
              control={control}
              name="lastDeal"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Value of Last Deal"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            /> */}
          </div>
          <Controller
            control={control}
            name="twitterFollowers"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Twitter Followers"
                variant="outlined"
                fullWidth
                onChange={onChange}
                value={value}
                required
                style={{
                  marginTop: "24px"
                }}
              />
            )}
          />
          <div>
            <Controller
              control={control}
              name="instagramFollowers"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Instagram Followers"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="tiktokFollowers"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="TikTok Followers"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="deliverables"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Deliverables"
                  helperText="What do they want you to do?"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="stats"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Stats"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="accolades"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Accolades"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  required
                  multiline
                  minRows={4}
                  style={{
                    marginTop: "24px"
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value, name } }) => (
                <FormControl
                  component="fieldset"
                  style={{
                    marginTop: "24px"
                  }}>
                  <FormLabel component="legend">Interested in working with this brand?</FormLabel>
                  <RadioGroup
                    aria-label="interest"
                    name={name}
                    onChange={onChange}
                    value={value}
                    row
                    required
                  >
                    {interestOptions.map((option) => (
                      <FormControlLabel
                        color="secondary"
                        key={option.value}
                        value={option.value}
                        label={option.label}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              marginTop: "24px"
            }}
            startIcon={loading ? (
              <CircularProgress
                size="1rem"
                color="white"
              />
            ) : null}
            disabled={loading}
          >
            {loading ? 'Submitting' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
};