import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from "@material-ui/core";
import { deleteNote, fetchNote, putNote } from "../api/notes";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  input: {
    marginTop: theme.spacing(1),
    borderColor: theme.palette.text.secondary,
    borderWidth: 1,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    width: '100%',
    resize: 'vertical'
  },
  card: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.text.secondary,
    borderWidth: 1,
    marginBottom: theme.spacing(2),
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
  },
  details: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse'
  }
}));

export default function Note() {
  const classes = useStyles();
  const { noteId } = useParams()

  const { notes, loadingNotes } = useReduxQuery("Notes", () => fetchNote(noteId));
  const currentNote = notes && notes[noteId] ? notes[noteId] : ''

  return (
    <div className={classes.container}>
      <NoteArea
        state={
          currentNote
            ? "loaded"
            : loadingNotes
              ? "loading"
              : "empty"
        }
        currentNote={currentNote || ''}
      />
    </div>
  );
}
const NoteArea = ({ currentNote, state }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(currentNote.note || '');
  const [isSaving, setIsSaving] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch();
  const { noteId } = useParams()

  const handleNoteUpdate = (val) => {
    setInputValue(val)
    setIsDirty(val !== currentNote.note)
  }

  const handleSaveNote = async () => {
    try {
      if (isDirty) {
        setIsSaving(true)
        await dispatch(putNote({ id: noteId, note: inputValue }));
        setIsDirty(false)
        setIsSaving(false)
        setIsSuccess(true)
        setTimeout(() => setIsSuccess(false), 1000)
      }
    } catch (error) {
      setIsSaving(false)
      alert(error);
    }
  };

  const handleDeleteNote = async () => {
    await dispatch(deleteNote(noteId));
    history.replace(`/notes`)
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  // Handle navigating away if note hasnt been saved
  useEffect(() => {
    const unblock = history.block(() => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          "Are you sure you want to leave? Changes you made may not be saved."
        );
        return confirmLeave;
      }
      return true;
    });

    return () => unblock();
  }, [isDirty, history]);

  // Handle refreshing if note hasnt been saved
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);

  return (
    <div>
      <div style={{
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div>
          <Link to="/notes" style={{
            display: "inline-flex",
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="8px"
            />
            <span style={{ paddingLeft: '4px' }}>Back to Notes</span>
          </Link>
          <h2 className={classes.h2}>Note</h2>
        </div>
        {currentNote ? (
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>
            <button
              style={{
                color: "#FF4D4D",
                backgroundColor: "#FF4D4D20",
                borderRadius: 25,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                textTransform: "uppercase",
                fontSize: 14,
                fontWeight: "bold",
                border: "none",
                cursor: "pointer",
                fontFamily: "inherit"
              }}
              onClick={handleOpenDialog}
            >
              Delete
            </button>
            <button
              style={{
                color: "#4B7BEC",
                backgroundColor: "#4B7BEC20",
                borderRadius: 25,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                textTransform: "uppercase",
                fontSize: 14,
                fontWeight: "bold",
                border: "none",
                cursor: "pointer",
                fontFamily: "inherit"
              }}
              onClick={handleSaveNote}
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
      {currentNote
        ? (
          <div>
            <div className={classes.details}>
              <p style={{
                color: 'gray',
              }}>
                <span>Last saved: {dayjs(currentNote.updatedAt).format("MMMM D, YYYY h:mmA")}</span>
              </p>
              <div>
                {isSaving ? (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    color: "#4B7BEC",
                  }}>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="14px"
                      spin
                    />
                    <span>Saving...</span>
                  </div>
                ) : isSuccess ? (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    color: "#008907"
                  }}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="14px"
                    />
                    <span>Saved!</span>
                  </div>
                ) : (
                  <p style={{
                    color: '#e48c00',
                    visibility: isDirty ? 'visible' : 'hidden'
                  }}>
                    <span>You have unsaved changes</span>
                  </p>)}
              </div>
            </div>
            <TextareaAutosize
              className={classes.input}
              onChange={(e) => {
                handleNoteUpdate(e.target.value)
              }}
              defaultValue={inputValue}
              placeholder="Add a new note..."
              minRows={24}
            />
          </div>
        ) : null}

      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this note?
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}
            color="primary"
            size="large"
            autoFocus
            style={{
              textTransform: 'unset',
              fontWeight: 'bold'
            }}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteNote}
            color="secondary"
            size="large"
            style={{
              textTransform: 'unset',
              fontWeight: 'bold'
            }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}