import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react'
import Hero from '../Components/Login/Hero';
import arrow from "../images/arrow-right-white.svg";
import setStyles from '../setStyles';
import { axiosHandler } from '../utils/utils';
import { Link } from 'react-router-dom';

export default function RequestAccount() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [success, setSuccess] = useState()

  useEffect(() => {
    var imgElements = document.querySelectorAll("img");
    imgElements.forEach(function (imgElement) {
      if (imgElement.src?.includes("https://dante-chatbot-pictures")) {
        // Remove the img element
        imgElement.remove();
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true);
      const json = await axiosHandler({
        url: '/auth/request-account',
        method: "POST",
        data: {
          firstName,
          lastName,
          email,
          phone
        },
      });
      setSuccess(json.data.success)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  return (
    <Grid
      container
      alignContent="flex-start"
      className={classes.pageContainer}
    >
      <Hero />
      <Grid
        item
        sm={12}
        md={6}
        className={classes.gridItem}
      >
        <div style={{
          maxWidth: "380px",
          width: "100%",
          margin: "0 auto",
        }}>
          <div
            style={{
              marginBottom: "40px"
            }}
          >
            <h1 className={classes.h1}>Request an Account</h1>
            <p>After a quick verification process, we'll send you a link to finish setting up your account.</p>
          </div>
          {success ? (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your account request was successful. You will receive an email to complete your account registration in a few days.
            </Alert>
          ) : (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px"
              }}>
              <div>
                <label htmlFor="firstName" className={classes.label}>First Name</label>
                <input
                  id="firstName"
                  autoComplete="given-name"
                  className={classes.input}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName" className={classes.label}>Last Name</label>
                <input
                  id="lastName"
                  autoComplete="family-name"
                  className={classes.input}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className={classes.label}>Email</label>
                <input
                  id="email"
                  autoComplete="email"
                  className={classes.input}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone" className={classes.label}>Phone Number</label>
                <input
                  id="phone"
                  autoComplete="tel"
                  className={classes.input}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className={classes.button}
                disabled={loading || success}
                style={{
                  position: "relative"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <CircularProgress
                    size="1.5rem"
                    color="white"
                    style={{
                      display: loading ? "block" : "none"
                    }}
                  />
                </div>
                <span
                  style={{
                    visibility: loading ? "hidden" : "visible"
                  }}
                >
                  <span>Request Account</span>
                  <img src={arrow} className={classes.arrowImg} aria-hidden="true" alt="" />
                </span>
              </button>
            </form>

          )}
          <div style={{
            marginTop: "32px",
            textAlign: "center"
          }}>
            <p>Have an account? <Link to="/" style={{ color: "#888888" }}>Log In</Link></p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: "fixed",
    overflow: "auto",
    inset: 0,
    width: "100%",
    height: "100%",
  },
  gridItem: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
    padding: "80px 0",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  h1: {
    fontSize: "36px",
    ...setStyles.sofiaProBold,
  },
  label: {
    display: "block",
    padding: "0px 0px 10px 25px",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  input: {
    boxShadow: "0px 4px 10px #05070B33",
    borderRadius: "30px",
    width: "380px",
    padding: "16px 24px",
    margin: "auto",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProRegular,
  },
  button: {
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    padding: "0 24px",
    height: "36px",
    backgroundColor: "#FF002B",
    borderRadius: "18px",
    border: "none",
    ...setStyles.sofiaProBold,
    fontSize: "17px",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  arrowImg: {
    paddingLeft: "5px",
    width: "20px",
  },
}));
