import React, { useState } from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";

import {
  makeStyles,
  withStyles,
  StylesProvider,
} from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "238px",
    height: "50px",
    margin: "auto",
    borderRadius: "25px",
    backgroundColor: "white",
    boxShadow: "0px 2px 8px #00000040",
    padding: "0px 2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainButton: {
    width: "100%",
    fontSize: "18px",
    borderRadius: "25px",
    color: "#FF002B",
    cursor: "pointer",
    ...setStyles.sofiaProBold,
    "&:hover": {
      background: "rgba(255, 0, 43, 0.2)",
    },
  },
  // menuItemSel: {
  //   background: "rgba(255, 0, 43, 0.2)",
  //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //     color: "#FF002B",
  //   },
  //   "&:hover": {
  //     background: "rgba(255, 0, 43, 0.2)",
  //   },
  // },
  menuItem: {
    ...setStyles.sofiaProBold,
    fontSize: "18px",
    padding: "15px 22px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginTop: "5px",
    width: "238px",
    borderRadius: "25px",
    boxShadow: "0px 2px 8px #00000040",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const MobileTabsNav = ({ labels, activeTab, selectTab }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (label) => {
    selectTab(label);
    handleClose();
  };

  const visibleLabels = labels.filter((label) => label !== activeTab)

  return (
    <div className={classes.container}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.mainButton}
      >
        {activeTab}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menuContainer}
      >
        {visibleLabels.map((label) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleSelect(label)}
            key={label}
          >
            {label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default MobileTabsNav;
