import { getPlayerDeals } from "../redux/playerDeals";
import { axiosHandler } from "../utils/utils";

export const submitPlayerDeal = (data) => async (_dispatch, getState) => {
    try {
        const state = getState();
        const json = await axiosHandler({
            url: '/player-deals/submit',
            data,
            method: "POST",
            state,
        });
        return json.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const fetchPlayerDeals = (userId) => async (dispatch, getState) => {
    try {
        const json = await axiosHandler({
            url: `/player-deals?userId=${userId}`,
            method: "GET",
            state: getState(),
        });
        await dispatch(getPlayerDeals(json?.data));
    } catch (error) {
        console.log(error);
        throw error;
    }
};