/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import Arrow from "../../images/Icons/Arrow";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { notificationTypes } from "../../utils/constants";
import dayjs from "dayjs";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { fetchEvent } from "../../api/events";
import { fetchContact } from "../../api/contacts";
import { fetchArticle } from "../../api/articles";
import FeatureCardSmall from "../Standalones/FeatureCardSmall";
import ContactCard from "../Standalones/ContactCard";
import ArticleCard from "../Standalones/ArticleCard";

const NotificationCard = ({
  description,
  title,
  isRead,
  objectId,
  type,
  createdAt,
  data,
}) => {
  const classes = useStyles();

  const href =
    type === notificationTypes.EVENT
      ? `/event/${objectId}`
      : type === notificationTypes.EDUCATION
        ? `/article/${objectId}`
        : type === notificationTypes.PROFESSIONAL_SERVICE
          ? `/professionalservices#${objectId}`
          : type === notificationTypes.LEAGUE_ACCESS
            ? `/leagueaccess#${objectId}`
            : type === notificationTypes.CONTACT
              ? `/marketingcontacts#${objectId}`
              : [notificationTypes.TWITTER, notificationTypes.ITL]?.includes(type)
                ? data?.url
                : "#";

  const hasType = [
    notificationTypes.EVENT,
    notificationTypes.EDUCATION,
    notificationTypes.PROFESSIONAL_SERVICE,
    notificationTypes.LEAGUE_ACCESS,
    notificationTypes.CONTACT,
    notificationTypes.TWITTER,
    notificationTypes.ITL,
  ].includes(type);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <div
        className={!isRead ? classes.ArticleCard : classes.ArticleCardDisabled}
      >
        <a
          className={classes.p4}
          href={href}
          target={data?.url ? "_blank" : "_self"}
          rel="noopener noreferrer"
        >
          <div>
            <p className={classes.p1}>{title}</p>
            {hasType && <p className={classes.type}>{type}</p>}
            <div className={classes.cont}>
              <ObjectWrapper objectId={objectId} type={type} />
            </div>
            <p className={classes.p2}>{description}</p>
            <p className={classes.p3}>
              {dayjs(createdAt).format("MMM DD, YYYY")}
            </p>
          </div>
          <span className={classes.p4}>
            {(objectId || data?.url) && <Arrow color="lightGrey" />}
          </span>
        </a>
      </div>
    </Grid>
  );
};

export default NotificationCard;

const ObjectWrapper = ({ objectId, type }) => {
  if (!objectId) return null;
  if (type === notificationTypes.EDUCATION)
    return <ArticleContent articleId={objectId} />;
  if (type === notificationTypes.EVENT)
    return <EventContent eventId={objectId} />;
  if (
    [
      notificationTypes.CONTACT,
      notificationTypes.LEAGUE_ACCESS,
      notificationTypes.PROFESSIONAL_SERVICE,
    ]?.includes(type)
  )
    return <ContactContent contactId={objectId} />;
};

const ArticleContent = ({ articleId }) => {
  const { article, loadingArticle } = useReduxQuery(
    "Article",
    () => fetchArticle(articleId),
    { selector: (state) => state.articles.data[articleId] }
  );

  if (loadingArticle) return <CircularProgress />;

  return (
    <div style={{ marginTop: 20 }}>
      <ArticleCard
        subject={article.tags?.join(", ")}
        heading={article.title}
        content={article.description}
        id={article.id}
      />
    </div>
  );
};

const EventContent = ({ eventId }) => {
  const { event, loadingEvent } = useReduxQuery(
    "Event",
    () => fetchEvent(eventId),
    {
      selector: (state) => state.events.data?.[eventId],
    }
  );
  if (loadingEvent) return <CircularProgress />;
  const location =
    (event?.city || event?.state)
      ? `${event?.city || ""}${event?.city && event?.state ? ", " : ""}${event?.state || ""
      }`
      : "";

  return (
    <div style={{ height: "350px", width: "300px", marginTop: 20 }}>
      <FeatureCardSmall
        heading={event?.title}
        location={location}
        content={event?.description?.substring(0, 70)}
        date={event?.startDate}
        categories={event?.categories || []}
        id={event?.id}
        coverUrl={event?.coverUrl}
      />
    </div>
  );
};

const ContactContent = ({ contactId }) => {
  const { contact, loadingContact } = useReduxQuery(
    "Contact",
    () => fetchContact(contactId),
    {
      selector: (state) => state?.contacts?.data?.[contactId],
    }
  );
  if (loadingContact) return <CircularProgress />;

  return (
    <ContactCard
      name={`${contact.firstName} ${contact.lastName}`}
      team={contact?.team?.name}
      title={contact.role}
      phone={contact.phone}
      email={contact.email}
      logoUrl={contact?.team?.logoUrl}
      lat={contact?.lat}
      lon={contact?.lon}
      contact={contact}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  ArticleCard: {
    // maxHeight: "200px",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    padding: "18px",
    backgroundColor: "white",
    margin: "20px 50px",
  },
  ArticleCardDisabled: {
    // maxHeight: "200px",
    borderRadius: "10px",
    padding: "18px",
    border: "3px solid #EEE",
    // backgroundColor: "#EEE",
    margin: "20px 50px",
  },
  type: {
    ...setStyles.sofiaProBlack,
    color: "#A28C62",
    fontSize: 12,
    textTransform: "uppercase",
    marginTop: 10,
  },
  p1: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "20px",
    color: "#05070b",
  },
  p2: {
    fontSize: "14px",
    color: "#011627",
    padding: "4px 0px",
    lineHeight: "1.333",
  },
  p3: {
    ...setStyles.sofiaProRegular,
    fontSize: "12px",
    color: "#05070b",
    padding: "12px 0px",
    lineHeight: "1.375",
    opacity: "0.75",
  },
  p4: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "14px",
    color: "#9b9c9d",
    display: "flex",
    justifyContent: "space-between",
    textDecoration: "none",
    alignItems: "center",
  },
  img: {
    paddingLeft: "5px",
    width: "19px",
    opacity: "0.6",
  },
  arrow: {
    paddingLeft: "5px",
  },
  cont: {
    marginBottom: "20px",
  },
}));
