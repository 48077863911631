import {
  loadNotifications,
  loadUnreadNotifications,
  putNotification,
} from "../redux/notifications";
import { axiosHandler } from "../utils/utils";

export const fetchNotifications = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `notifications`,
      state: getState(),
    });
    await dispatch(loadNotifications(json.data));
  } catch (error) {
    throw error;
  }
};

export const fetchNewNotifications = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `notifications?read=0`,
      state: getState(),
    });
    await dispatch(loadUnreadNotifications(json.data));
  } catch (error) {
    throw error;
  }
};

export const updateNotification = (id, value) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `notifications/${id}`,
      method: "PUT",
      data: { ...value },
      state: getState(),
    });
    await dispatch(putNotification(json?.data));
  } catch (error) {
    throw error;
  }
};

export const createNotification = (value) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `notifications/create`,
      method: "POST",
      data: { ...value },
      state: getState(),
    });
    return json.data;
  } catch (error) {
    throw error;
  }
};
