import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import MainMenu from "../Navigation/NavBar/MainMenu";
import Darken from "../Navigation/Darken";
import WelcomeHeader from "./WelcomeHeader";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import bgImage from "../../images/bg-menu.svg";
import logo2 from "../../images/logo2.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: "100%",
    minHeight: "350px",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: "left bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  minimalHeaderContainer: {
    width: "100%",
    backgroundColor: "#05070B",
  },
  innerContainer: {
    width: "100%",
    maxWidth: setStyles.maxWidth,
    margin: "auto",
  },
  stickyHeader: {
    position: "fixed",
    width: "100%",
    backgroundColor: "#05070B",
    top: 0,
    left: 0,
    zIndex: 9998,
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
  menuContainer: {
    maxWidth: setStyles.maxWidth,
    margin: "auto",
  },
  logoContainer: {
    padding: "10px 0px",
    width: 200,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function PlayerHeader({ removeWelcome, minimal }) {
  const classes = useStyles();
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={
        minimal ? classes.minimalHeaderContainer : classes.headerContainer
      }
    >
      <Darken showMore={showMore} closeMenu={() => setShowMore(false)} />
      <div className={classes.innerContainer}>
        <div className={classes.stickyHeader}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.menuContainer}
          >
            <div className={classes.logoContainer}>
              <img src={logo2} height={50} alt="logo" />
            </div>
            <MainMenu
              showMore={showMore}
              openMenu={() => setShowMore(true)}
              closeMenu={() => setShowMore(false)}
              pathName={history.location.pathname}
            />
          </Grid>
        </div>
        <WelcomeHeader hide={removeWelcome} />
      </div>

    </div>
  );
};
