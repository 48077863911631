// Actions
const LOAD_UNIQUE = "playerNotes/LOAD_UNIQUE";
const UPDATE_NOTE = "playerNotes/UPDATE_NOTE"

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_UNIQUE:
      return {
        ...state,
        data: { ...state.data, [action.playerNote.id]: action.playerNote },
      };
    case UPDATE_NOTE:
      return {
        ...state,
        data: { ...state.data, [action.playerNote.id]: action.playerNote },
      };
    default:
      return state;
  }
}

// Action Creators
export function loadPlayerNote(playerNote) {
  return { type: LOAD_UNIQUE, playerNote };
}
export function updatePlayerNote(playerNote) {
  return { type: UPDATE_NOTE, playerNote };
}
